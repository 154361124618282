<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-base py-7 mb--100">
          <div class="container">
        <div class="text-center mt--5 mb-5">
          <div class="row justify-content-center">
            <div class="text-center" style="margin-bottom: 0px">
              <h1 class="text-white">{{$t("logIn.welcomeBack")}}</h1>
              <p class="text-lead text-white">
                {{$t("logIn.makeEveryMomentRemarkableOrganiseAndManageTheBest")}}
              </p>
              <ul class="list-inline">
                <li
                  class="list-inline-item bg-white"
                  v-for="(value, index) in tagline"
                  :key="index"
                >
                  &nbsp;#{{ value }}&nbsp;
                </li>
              </ul>
            </div>

            <div class="col-12 text-center mt-2">
               </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Page content -->
    <div class="container mt--8 pb-4">

      <div class="row justify-content-center mb--2">
        <div class="col-md-6">
          <card id="cta_new_event" type="frame">
            <div class="row justify-content-center">
              <div class="col-md-7 text-center mb-1 mt-1">
                <p class="surtitle text-muted">
                  {{$t("logIn.planWithPurpose")}}
                </p>
                <h6 class="surtitle text-muted mt-1">
                  {{$t("logIn.executeFasterThanLight")}}
                </h6>
              </div>
              <div class="col-md-5 text-center mt-1">
                <base-button
                    type="danger"
                    class="bg-gradient-danger"
                    icon
                    size="md"
                    @click="addGuestItem()"
                    data-testid="guest-create-item"
                >
                  <i class="fas fa-calendar-day" />
                  {{$t("logIn.createEvent")}}
                </base-button>
              </div>
            </div>
          </card>

        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card bg-gradient-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <h3>{{$t("logIn.signInWithCredentials")}}</h3>
              </div>
              <form role="form" @submit.prevent="handleSubmit()">
                <base-input
                  class="mb-3"
                  name="Email"
                  type="email"
                  prepend-icon="ni ni-email-83"
                  placeholder="Email"
                  v-model="email"
                  required
                />
                <validation-error :errors="apiValidationErrors?.email" />

                <base-input
                  class="mb-1"
                  name="Password"
                  prepend-icon="ni ni-lock-circle-open"
                  type="password"
                  :placeholder="$t('logIn.password')"
                  v-model="password"
                  required
                >
                </base-input>
                <validation-error :errors="apiValidationErrors?.password" />

                <div class="text-center mr-3 mt-5">
                  <base-button
                      block
                    type="primary"
                    native-type="submit"
                    class="my-2"
                    :disabled="loading"
                  >
                   {{$t('logIn.signIn')}}
                  </base-button>
                </div>
                <div class="text-center">
                  <slot name="loading">
                    <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                  </slot>
                </div>
              </form>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-6">
              <a href="/register" class="text-light"
                ><small> <i class="fa fa-user" /> {{$t("logIn.createFreeAccount")}}</small>
              </a>
            </div>
            <div class="col-6 text-right">
              <a href="/password/reset" class="text-light"
                ><small>
                  <i class="fa fa-asterisk" /> {{$t("logIn.forgotYourPassword")}}
                </small>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div v-if="demoEnvironment" class="row justify-content-center mb--2 mt-5">
        <div class="col-md-6">
          <card id="cta_new_event" type="frame" gradient="warning">
            <div class="row justify-content-start">
              <div class="col text-center text-white mb-1 mt-1">
                <h4 class="text-muted text-white mt-1">{{ demoURL }}</h4>

                <h6 class="surtitle text-muted text-white mt-1">
                  This is a Sandboxed environment - only for DEMO purposes. Any event data or ticket payments that are being generated under this url
                  are not corresponding to real-user data
                </h6>

              </div>
            </div>
          </card>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import GuestAddEventItem from "@/views/Pages/Guest/PublicAddEventItem.vue";
import { Select, Option } from "element-ui";

import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import router from "@/router";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationError,
  },
  mixins: [formMixin],
  data () {
     return{
      email: "",
    password: "",
    tagline: ["event", "attendees", "tickets", "venues", "tags" ],
    loading: false,
    langs: ["en", "el"],
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    demoEnvironment() {
      if (process.env.VUE_APP_ENV === "staging" || process.env.VUE_APP_ENV === "test" ){
        if( process.env.VUE_APP_ENV !== "production") return true;
      }
      return false;
    },
    demoURL() {
      return process.env.VUE_APP_BASE_URL;
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      setTimeout(() => {
        const user = {
          data: {
            type: "token",
            attributes: {
              email: this.email,
              password: this.password, 
            },
          },
        };

        const requestOptions = {
          headers: {
            "Accept" : "application/vnd.api+json",
            "Content-Type" : "application/vnd.api+json",
          },
        };

          this.$store.dispatch("login", { user, requestOptions })
              .then( res => {
                this.loading = false;
                // Successful Response
                console.info("Successful Login");

                // Redirect to dashboard after login
                router.push({ path: "/" });

                // Notification message
                this.$notify({
                  type: "success",
                  message: this.$t('logIn.welcomeBack'),
                });

              }).catch(error => {
                this.loading = false;

                // Notification message
                this.$notify({
                  type: "danger",
                  message: this.$t('logIn.invalidCredentials'),
                });
                this.setApiValidation(error.response.data.errors, 'email');

                console.error("Failed Login", error.response.data.errors[0]);
              });
      }, 500);
    },
    login() {
      console.log("Send");
    },
    addGuestItem() {
      this.$router.push({ name: "Public Add Event Item" });
    },
      getLanguageLabel(lang) {
          return lang[0].toUpperCase() + lang.slice(1);
        },
  },
};
</script>
<style>
.language-selector {
  top: -70px;
  right: -600px;
  z-index:1000;
}
</style>