<template>
  <div class="mt-6">
    <div class="row justify-content-center bg-gradient-secondary">
      <div class="col-md-10 mt-5 mb-7">
        <el-steps
          :active="activeStep"
          finish-status="success"
          class="col-md-12 mb-2 bg-white"
          simple
        >
          <!-- Step 1 - Register To Begin -->
          <el-step
            title="Begin With Registration"
            icon="el-icon-s-ticket"
          ></el-step>
          <!-- Step 2 - Choose Your Offer -->
          <el-step title="Select Your Offer" icon="el-icon-s-order"></el-step>
          <!-- Step 3 - Activate Membership -->
          <el-step title="Activate Membership" icon="el-icon-s-claim"></el-step>
        </el-steps>

        <div class="col-md-6 offset-3 mt-5">
          <!-- Step 1 - Register To Begin -->
          <template v-if="activeStep === 0">
            <base-register
              :onboarding="true"
              styling="bg-white border-base"
              @registered="userRegistered($event)"
            ></base-register>
          </template>

          <!-- Step 2 - Choose Your Offer -->
          <template v-if="activeStep === 1">
            <div class="card border-base">
              <div class="card-body px-lg-5 py-lg-5 mb-5 mt-auto">
                <div class="text-center text-muted mb-4">
                  <h3>Choose Your Offer</h3>
                </div>
                <base-input>
                  <div
                    class="dropzone align-items-center radio-group ml-2 mt-2 mb--2"
                  >
                    <base-radio
                      name="lifetime-deal"
                      class="custom-radio mb-2"
                      v-model="offerType"
                    >
                      Lifetime Deal (LTD)
                    </base-radio>

                    <div v-if="offerType === 'lifetime-deal'">
                      <!-- AppSumo Logo Image -->
                      <img
                        class="mt--5"
                        src="https://appsumo2next-cdn.appsumo.com/_next/static/media/as-appsumo-logo-dark.fbc325ee.svg"
                        alt="AppSumo Logo"
                        style="height: 100px; width: 100px"
                      />

                      <card gradient="default">
                        <h3 class="card-title text-white">
                          To make things easier read these quick steps:
                        </h3>
                        <blockquote class="blockquote text-white mb-0">
                          <ul class="list-unstyled my-4">
                            <li>
                              <div class="d-flex align-items-center">
                                <div>
                                  <div
                                    class="icon icon-xs icon-shape shadow rounded-circle"
                                  >
                                    <i class="fas fa-check"></i>
                                  </div>
                                </div>
                                <div>
                                  <span class="pl-2 text-sm"
                                    >Login to AppSumo and grab-copy the Coupon
                                    Code</span
                                  >
                                </div>
                              </div>
                            </li>
                          </ul>

                          <ul class="list-unstyled my-4">
                            <li>
                              <div class="d-flex align-items-center">
                                <div>
                                  <div
                                    class="icon icon-xs icon-shape shadow rounded-circle"
                                  >
                                    <i class="fas fa-check"></i>
                                  </div>
                                </div>
                                <div>
                                  <span class="pl-2 text-sm"
                                    >On the next step select your Plan (Tier 1,
                                    Tier 2 or Tier 3)</span
                                  >
                                </div>
                              </div>
                            </li>
                          </ul>

                          <ul class="list-unstyled my-4">
                            <li>
                              <div class="d-flex align-items-center">
                                <div>
                                  <div
                                    class="icon icon-xs icon-shape shadow rounded-circle"
                                  >
                                    <i class="fas fa-check"></i>
                                  </div>
                                </div>
                                <div>
                                  <span class="pl-2 text-sm"
                                    >Click on the "Activate Plan" and wait for
                                    the Payment Portal</span
                                  >
                                </div>
                              </div>
                            </li>
                          </ul>

                          <ul class="list-unstyled my-4">
                            <li>
                              <div class="d-flex align-items-center">
                                <div>
                                  <div
                                    class="icon icon-xs icon-shape shadow rounded-circle"
                                  >
                                    <i class="fas fa-check"></i>
                                  </div>
                                </div>
                                <div>
                                  <span class="pl-2 text-sm"
                                    >Paste your Coupon Code in the "Add
                                    promotion code" field
                                  </span>
                                </div>
                              </div>
                            </li>
                          </ul>

                          <ul class="list-unstyled my-4">
                            <li>
                              <div class="d-flex align-items-center">
                                <div>
                                  <div
                                    class="icon icon-xs icon-shape shadow rounded-circle"
                                  >
                                    <i class="fas fa-check"></i>
                                  </div>
                                </div>
                                <div>
                                  <span class="pl-2 text-sm"
                                    >You will get 100% discount on the Plan
                                    Price forever
                                  </span>
                                </div>
                              </div>
                            </li>
                          </ul>

                          <footer class="blockquote-footer text-danger">
                            It's straightforward isn't it, for more:
                            <cite title="AppSumo Tutorial">
                              <a
                                href="https://help.appsumo.com/article/38-codes-and-redemption-instructions"
                                class="text-white"
                              >
                                Offer Details
                              </a>
                            </cite>
                          </footer>
                        </blockquote>
                      </card>
                    </div>

                    <base-radio
                      name="discount-coupon"
                      class="custom-radio mb-2"
                      v-model="offerType"
                      :disabled="true"
                    >
                      Discount Coupon
                    </base-radio>

                    <base-radio
                      name="affiliate-code"
                      class="custom-radio mb-2"
                      v-model="offerType"
                      :disabled="true"
                    >
                      Affiliate Code
                    </base-radio>
                  </div>
                </base-input>

                <base-button
                  size="lg"
                  block="true"
                  type="primary"
                  native-type="submit"
                  class="my-4"
                  :disabled="!offerType"
                  @click="activeStep++"
                >
                  Select Plan
                </base-button>
              </div>
            </div>
          </template>

          <!-- Step 3 - Activate Membership -->
          <template v-if="activeStep === 2">
            <div class="mt-4 mb-5">
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <div class="pricing card-group flex-column flex-md-row mb-3">
                    <!-- Tier 1 Pack -->
                    <card
                      header-classes="bg-transparent"
                      body-classes="px-lg-4"
                      class="card-pricing border-1 border-base text-center mb-4"
                    >
                      <h4
                        slot="header"
                        class="text-uppercase ls-1 text-primary py-3 mb-0"
                      >
                        <h3>Tier 1 Plan</h3>
                      </h4>

                      <div>
                        <div class="display-2">
                          <small class="text-uppercase text-small">$</small>
                          599.99
                        </div>

                        <badge
                          v-if="subscriptionLength === 'ltd'"
                          rounded
                          type="base"
                          size="sm"
                          class="mb-2"
                        >
                          forever
                        </badge>
                      </div>
                      <small class="text-muted text-capitalize"
                        >Suitable for:</small
                      >

                      <ul class="list-unstyled my-4 pl--2">
                        <li>
                          <div class="d-flex align-items-left">
                            <div>
                              <div
                                class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white"
                              >
                                <i class="fas fa-check"></i>
                              </div>
                            </div>
                            <div>
                              <small class="pl-2"
                                >Weddings or Baby Showers</small
                              >
                            </div>
                          </div>
                        </li>

                        <li>
                          <div class="d-flex align-items-left">
                            <div>
                              <div
                                class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white"
                              >
                                <i class="fas fa-check"></i>
                              </div>
                            </div>
                            <div>
                              <small class="pl-2"
                                >Gatherings or Birthdays</small
                              >
                            </div>
                          </div>
                        </li>

                        <li>
                          <div class="d-flex align-items-left">
                            <div>
                              <div
                                class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white"
                              >
                                <i class="fas fa-check"></i>
                              </div>
                            </div>
                            <div>
                              <small class="pl-2">Private Celebrations</small>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div class="d-flex align-items-left">
                            <div>
                              <div
                                class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white"
                              >
                                <i class="fas fa-check"></i>
                              </div>
                            </div>
                            <div>
                              <small class="pl-2"
                                >Graduations or Launch Events</small
                              >
                            </div>
                          </div>
                        </li>
                      </ul>
                      <base-button
                        @click="submitOffer('tier-1')"
                        type="primary"
                        class="mb-3"
                        :disabled="loading"
                      >
                        Activate Plan
                      </base-button>
                      <a slot="footer">
                        <div class="text-center">
                          <small> * One Time Offer</small>
                          <slot name="loading">
                            <i
                              v-if="loading"
                              class="fas fa-spinner fa-spin"
                            ></i>
                          </slot>
                        </div>
                      </a>
                    </card>

                    <!-- Tier 2 Pack -->
                    <card
                      header-classes="bg-transparent"
                      body-classes="px-lg-4"
                      class="card-pricing border-1 border-base text-center mb-4"
                    >
                      <h4
                        slot="header"
                        class="text-uppercase ls-1 text-primary py-3 mb-0"
                      >
                        <h3>Tier 2 Plan</h3>
                      </h4>

                      <div>
                        <div class="display-2">
                          <small class="text-uppercase text-small">$</small>
                          999.99
                        </div>

                        <badge
                          v-if="subscriptionLength === 'ltd'"
                          rounded
                          type="base"
                          size="sm"
                          class="mb-2"
                        >
                          forever
                        </badge>
                      </div>
                      <small class="text-muted text-capitalize"
                        >Suitable for:</small
                      >

                      <ul class="list-unstyled my-4 pl--2">
                        <li>
                          <div class="d-flex align-items-left">
                            <div>
                              <div
                                class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white"
                              >
                                <i class="fas fa-check"></i>
                              </div>
                            </div>
                            <div>
                              <small class="pl-2"
                                >Weddings or Baby Showers</small
                              >
                            </div>
                          </div>
                        </li>

                        <li>
                          <div class="d-flex align-items-left">
                            <div>
                              <div
                                class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white"
                              >
                                <i class="fas fa-check"></i>
                              </div>
                            </div>
                            <div>
                              <small class="pl-2"
                                >Gatherings or Birthdays</small
                              >
                            </div>
                          </div>
                        </li>

                        <li>
                          <div class="d-flex align-items-left">
                            <div>
                              <div
                                class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white"
                              >
                                <i class="fas fa-check"></i>
                              </div>
                            </div>
                            <div>
                              <small class="pl-2">Private Celebrations</small>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div class="d-flex align-items-left">
                            <div>
                              <div
                                class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white"
                              >
                                <i class="fas fa-check"></i>
                              </div>
                            </div>
                            <div>
                              <small class="pl-2"
                                >Graduations or Launch Events</small
                              >
                            </div>
                          </div>
                        </li>
                      </ul>
                      <base-button
                        @click="submitOffer('tier-2')"
                        type="primary"
                        class="mb-3"
                        :disabled="loading"
                      >
                        Activate Plan
                      </base-button>
                      <a slot="footer">
                        <div class="text-center">
                          <small> * One Time Offer</small>
                          <slot name="loading">
                            <i
                              v-if="loading"
                              class="fas fa-spinner fa-spin"
                            ></i>
                          </slot>
                        </div>
                      </a>
                    </card>

                    <!-- Tier 3 Pack -->
                    <card
                      header-classes="bg-transparent"
                      body-classes="px-lg-4"
                      class="card-pricing border-1 border-base text-center mb-4"
                    >
                      <h4
                        slot="header"
                        class="text-uppercase ls-1 text-primary py-3 mb-0"
                      >
                        <h3>Tier 3 Plan</h3>
                      </h4>

                      <div>
                        <div class="display-2">
                          <small class="text-uppercase text-small">$</small>
                          2999.99
                        </div>

                        <badge
                          v-if="subscriptionLength === 'ltd'"
                          rounded
                          type="base"
                          size="sm"
                          class="mb-2"
                        >
                          forever
                        </badge>
                      </div>
                      <small class="text-muted text-capitalize"
                        >Suitable for:</small
                      >

                      <ul class="list-unstyled my-4 pl--2">
                        <li>
                          <div class="d-flex align-items-left">
                            <div>
                              <div
                                class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white"
                              >
                                <i class="fas fa-check"></i>
                              </div>
                            </div>
                            <div>
                              <small class="pl-2"
                                >Weddings or Baby Showers</small
                              >
                            </div>
                          </div>
                        </li>

                        <li>
                          <div class="d-flex align-items-left">
                            <div>
                              <div
                                class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white"
                              >
                                <i class="fas fa-check"></i>
                              </div>
                            </div>
                            <div>
                              <small class="pl-2"
                                >Gatherings or Birthdays</small
                              >
                            </div>
                          </div>
                        </li>

                        <li>
                          <div class="d-flex align-items-left">
                            <div>
                              <div
                                class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white"
                              >
                                <i class="fas fa-check"></i>
                              </div>
                            </div>
                            <div>
                              <small class="pl-2">Private Celebrations</small>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div class="d-flex align-items-left">
                            <div>
                              <div
                                class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white"
                              >
                                <i class="fas fa-check"></i>
                              </div>
                            </div>
                            <div>
                              <small class="pl-2"
                                >Graduations or Launch Events</small
                              >
                            </div>
                          </div>
                        </li>
                      </ul>
                      <base-button
                        @click="submitOffer('tier-3')"
                        type="primary"
                        class="mb-3"
                        :disabled="loading"
                      >
                        Activate Plan
                      </base-button>
                      <a slot="footer">
                        <div class="text-center">
                          <small>* One Time Offer</small>
                        </div>
                      </a>
                    </card>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// components
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseRegister from "@/components/BaseRegister.vue";
import { Steps, Step } from "element-ui";
export default {
  components: {
    BaseRegister,
    BaseInput,
    [Steps.name]: Steps,
    [Step.name]: Step,
  },
  data() {
    return {
      loading: false,
      activeStep: 0,
      offerType: null,
      offerTier: "tier-1",
      offerCode: null,
      subscriptionLength: "ltd",
    };
  },
  methods: {
    userRegistered(user) {
      this.activeStep = 1;
    },
    async submitOffer(type) {
      this.activeStep = 2;

      try {
        // Get User Profile
        await this.$store.dispatch("subscription/add", {
          package: type,
          status: this.subscriptionLength,
        });

        this.session = this.$store.getters["subscription/getSession"];

        window.location.href = this.session.url;

        this.loading = false;
      } catch (error) {
        // Notification
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrongWithYourAccount')+ '!',
        });
        this.loading = false;
      }
    },
    goToDashboard() {
      this.$router.push({ name: "Main Dashboard" });
    },
  },
};
</script>
<style></style>
